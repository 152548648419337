import React, { useRef, useEffect, useCallback } from 'react';
import { DataPortalAPI } from "@cimpress-technology/data-portal-core";
import { ConductorDetailsSectionModule } from "@cimpress-technology/conductor-package";
import { PDWRoleDetails } from './PdwRole/PDWRoleDetails';
import { UserDetails } from '@cimpress-technology/data-portal-core/lib/common';
import { useAppDispatchType, useAppSelectorType } from "@cimpress-technology/data-portal-core/lib/storeHooks";
import { DataProductDataSharesModule } from "@cimpress-technology/data-portal-pdw-data-sharing";
import { DataProduct, InputPortType, LookerDashboardsMetadataEmbed, OutputPortType } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi';
import { OutputPortHook } from '../../hooks/useOutputPorts';
import { setConductors } from "@cimpress-technology/data-portal-core/lib/features/resources/minConductorSlice";
import { DataDiscoveryDatasetMetadata } from '@cimpress-technology/data-portal-core/lib/interfaces/dataDiscovery';
import { DatabricksManagementUIProps } from '../../hooks/useDatabricks';
import { DbtManagementUIProps } from '../../hooks/useDbt';
import ResourcesPdwDatasetsMain from '../shared/resourcesPdwDatasets/ResourcesPdwDatasetsMain';
import { OtherSubTabs } from '../../interfaces/tabs';
import  { DatabricksDatasets } from './DatabricksDatasets';
import { PDWWarehouse } from './PDWWarehouse';



type ConductorExpectedOutputPortEmbeddedPropertiesMap = {
    [OutputPortType.PDWdataset]: DataDiscoveryDatasetMetadata;
    [OutputPortType.LookerDashboards]: LookerDashboardsMetadataEmbed;
};
interface ConductorModuleExpectedOutputPort extends Omit<DataPortalAPI.OutputPort<OutputPortType>, '_embedded'> {
    _embedded: ConductorExpectedOutputPortEmbeddedPropertiesMap[OutputPortType];
}
interface ConductorModuleExpectedDataProduct extends Omit<DataPortalAPI.DataProduct, 'inputPorts' | 'outputPorts' | 'resources' | 'adminCoamGroupId' | 'readCoamGroupId'> {
    inputPorts: DataPortalAPI.InputPort<InputPortType>[];
    outputPorts: ConductorModuleExpectedOutputPort[];
    resources: DataPortalAPI.DataResource<DataPortalAPI.DataResourceType>[];
    adminCoamGroupId: 'string';
    readCoamGroupId: 'string'; // This can be undefined but it doesn't matter for `Conductor`
}

const dataProducIsConductorAcceptable = (dataProduct: DataProduct): dataProduct is ConductorModuleExpectedDataProduct => {
    return (dataProduct.adminCoamGroupId !== undefined);
};

export const DataResourceDetailsSection: React.FC<{
    selectedTab: DataPortalAPI.DataResourceType | OtherSubTabs,
    dataProduct: DataPortalAPI.DataProduct,
    dataProductAccountId: string,
    userDetails: UserDetails,
    hasDataProductAccess: boolean,
    hasDataProductAdminAccess: boolean,
    pdwDatasetsHook: OutputPortHook<OutputPortType.PDWdataset>,
    databricksModuleProps: Partial<DatabricksManagementUIProps> | undefined,
    dbtModuleProps: Partial<DbtManagementUIProps> | undefined,
    useAppSelector: useAppSelectorType,
    useAppDispatch: useAppDispatchType,
    domain: DataPortalAPI.Domain
}> = ({ selectedTab, dataProduct, dataProductAccountId, userDetails, useAppSelector, useAppDispatch, hasDataProductAccess, hasDataProductAdminAccess, pdwDatasetsHook, databricksModuleProps, dbtModuleProps, domain }) => {

    const dispatch = useAppDispatch();
    const currentConductors = useAppSelector((state) => state.minimalConductors.conductors);

    const tellReduxIfChanges = (conductors: string[] | null) => {
        if (!(JSON.stringify(currentConductors) === JSON.stringify(conductors))) {
            // TODO: Remove type assertion once core is merged
            dispatch(setConductors({ conductors: conductors as string[], dataProductId: dataProduct.dataProductId }));
        }
    };

    const dataProductDetailsSection = useRef<HTMLInputElement>(null);

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const renderFunction = (window as any)?.dataportalresources?.databricks?.render;
    const databricksRenderFunction = useCallback( async (domElement) => {
        if(renderFunction != undefined)
        {
            await renderFunction(domElement, { ...databricksModuleProps, hasDataProductAccess });
        }

    }, [databricksModuleProps, hasDataProductAccess, renderFunction]);

    useEffect(() => {
        if (selectedTab === DataPortalAPI.DataResourceType.Databricks) {
            if (dataProductDetailsSection?.current != null &&
                dataProductDetailsSection?.current?.childElementCount == 0) {
                databricksRenderFunction(dataProductDetailsSection.current);
            }
        }
    }
        , [selectedTab, dataProductDetailsSection, databricksRenderFunction]);


    const dbtDataProductDetailsSection = useRef<HTMLInputElement>(null);
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const renderDbtFunction = (window as any)?.dataportalresources?.dbt?.render;
    const dbtRenderFunction = useCallback(async (domElement) => {
        if (renderDbtFunction != undefined) {
            await renderDbtFunction(domElement, { ...dbtModuleProps });
        }

    }, [dbtModuleProps, renderDbtFunction]);

    useEffect(() => {
        if (selectedTab === DataPortalAPI.DataResourceType.DBT) {
            if (dbtDataProductDetailsSection?.current != null &&
                dbtDataProductDetailsSection?.current?.childElementCount == 0) {
                dbtRenderFunction(dbtDataProductDetailsSection.current);
            }
        }
    }
        , [selectedTab, dbtDataProductDetailsSection, dbtRenderFunction]);


    return <>
        <div>
            {selectedTab === OtherSubTabs.resourcesPdwDataset && (<ResourcesPdwDatasetsMain dataProduct={dataProduct} hasDataProductAdminAccess={hasDataProductAdminAccess} hasDataProductReadAccess={hasDataProductAccess} domain={domain} pdwDatasetsHook={pdwDatasetsHook} />)}
            {selectedTab === DataPortalAPI.DataResourceType.Conductor && dataProducIsConductorAcceptable(dataProduct)
                ? <ConductorDetailsSectionModule dataProduct={dataProduct} hasDataProductAdminAccess={hasDataProductAdminAccess} tellDataPortal={tellReduxIfChanges} />
                : null
            }

            {selectedTab === DataPortalAPI.DataResourceType.Databricks
                ? <div ref={dataProductDetailsSection}></div> : null
            }
            {selectedTab === DataPortalAPI.DataResourceType.DBT
                ? <div ref={dbtDataProductDetailsSection}></div> : null
            }

            {selectedTab === DataPortalAPI.DataResourceType.DataSharing
                ? <DataProductDataSharesModule
                    dataProduct={dataProduct}
                    pdwDatasets={pdwDatasetsHook.outputPorts}
                />
                : null
            }
             {selectedTab === DataPortalAPI.DataResourceType.SnowflakeWarehouse
                ? <PDWWarehouse
                    dataProduct={dataProduct}
                    userDetails={userDetails}
                 
                />
                : null
            }
            {selectedTab === DataPortalAPI.DataResourceType.PDWRole
                ? (userDetails.accessToken && (
                    <PDWRoleDetails
                        accessToken={userDetails.accessToken}
                        useAppSelector={useAppSelector}
                        dataProductAccountId={dataProductAccountId}
                        dataProductId={dataProduct.dataProductId}
                        hasDataProductAdminAccess={hasDataProductAdminAccess}
                    />
                ))
                : null
            }
           {selectedTab === OtherSubTabs.resourcesDatabricksDatasets
                ? <DatabricksDatasets dataProduct={dataProduct} userDetails={userDetails} hasDataProductAdminAccess={hasDataProductAdminAccess} /> : null
            }

        </div>
    </>;
};
