import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { BreadcrumbItem, Breadcrumbs, colors, InlineEdit, Snackbar, CodeBlock, shapes } from '@cimpress/react-components';
import { DataPortalAPI, Clients, Hooks } from "@cimpress-technology/data-portal-core";
import { UserDetails } from "@cimpress-technology/data-portal-core/lib/common";
import { useDataProduct } from '../../../hooks/useDataProduct';
import { AuthContext } from '../../../context/authContext';
import { OutputPortsDetailsSection } from './OutputPortsDetailsSection';
import { renderError, renderLoading } from '../../shared/Render';
import { DataProductDetailsSection } from './DataProductDetailsSection';
import { DataProductDetailsNav, Tabs } from '../../shared/DataProductDetailsNav';
import { InputPortsDetailsSection } from './InputPortsDetailsSection';
import { DataResourceDetailsSection } from '../../dataProductResources/DataResourceDetailsSection';
import { AccessManagement } from './sections/accessManagement/AccessManagement';
import { OutputPortHook, useOutputPorts } from '../../../hooks/useOutputPorts';
import { parseErrorToAxiosErrorWrapper } from '@cimpress-technology/data-portal-core/lib/features/utils';
import { DataIssueTrackerDetailsSectionModule } from "../../issueTracker/IssueTrackerWrapper";
import { PublishUnpublishDataProductButton } from '../../shared/PublishUnpublishDataProductButton';
import { useAppDispatch, useAppSelector } from '../../../store/storeHooks';
import { usePdwRolesLoader, useConductorDataProductLoader } from '../../../store/useResourcesCallbacks';
import { useMemoizedLoaderForLookerDashboard, useMemoizedCleanErrorsForLookerDashboard } from '../../../store/useLookerCallbacks';
import { useMemoizedLoaderForPdwDatasets } from '../../../store/userPdwDatasetsCallbacks';
import { useMemoizedLoaderForRiverStreams, useMemoizedCleanErrorsForRiverStreams } from '../../../store/useRiverStreamCallbacks';
import { useMemoizedCleanErrorsForInputPortDatasets, useMemoizedLoaderForInputPortDatasets } from '../../../store/useInputPortDataset';
import { useMemoizedLoaderForCustomFields } from '../../../store/useCustomFields';
import { DeleteDataProduct } from '../../shared/DeleteDataProduct';
import { DataProductCostReport } from './sections/DataProductCostReport';
import { useDatabricks } from '../../../hooks/useDatabricks';
import { useDbt } from '../../../hooks/useDbt';
import { ReactAppEnvironment } from '../../../common';
import { appendScript } from '../../../utils/common';
import { useIssueTrackerModule } from "../../issueTracker/useIssueTrackerModule";
import { OtherSubTabs } from '../../../interfaces/tabs';
import { useMemoizedLoaderForResourcesPdwDatasets } from '../../../store/useResourcesPdwDatasets';
import { useMemoizedLoaderForPublishedPdwDatasets } from '../../../store/usePublishedPdwDatasets';
export function getSelectedTabs(query: URLSearchParams): { selectedTab: Tabs, selectedSubTab: DataPortalAPI.DataProductComponentType | OtherSubTabs | null } {
    const queryTab = query.get('tab') as Tabs;
    const querySubTab = query.get('subtab') as DataPortalAPI.DataProductComponentType | OtherSubTabs;
    const selectedTab = Object.values(Tabs).includes(queryTab) ? queryTab : Tabs.DataProductDetails;
    let selectedSubTab: DataPortalAPI.DataProductComponentType | OtherSubTabs | null;
    switch (selectedTab) {
        case Tabs.InputPorts:
            selectedSubTab = Object.values(DataPortalAPI.InputPortType).includes(querySubTab as DataPortalAPI.InputPortType) ? querySubTab : DataPortalAPI.InputPortType.RiverStream;
            break;
        case Tabs.Resources:
            {
                const allowed = (Object.values(DataPortalAPI.DataResourceType) as string[]).concat(Object.values(OtherSubTabs));
                selectedSubTab = allowed.includes(querySubTab) ? querySubTab : DataPortalAPI.DataResourceType.Conductor;
                break;
            }
        case Tabs.OutputPorts:
            selectedSubTab = Object.values(DataPortalAPI.OutputPortType).includes(querySubTab as DataPortalAPI.OutputPortType) ? querySubTab : DataPortalAPI.OutputPortType.PDWdataset;
            break;
        default:
        case Tabs.DataProductDetails:
        case Tabs.DataIssueTracker:
            selectedSubTab = null;
            break;
    }

    return { selectedTab, selectedSubTab };
}

export const DataProductDetails: React.FC = () => {
    const history = useHistory();
    const { search, pathname } = useLocation();
    const { dataProductId } = useParams<{ dataProductId: string }>();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const authContext = React.useContext(AuthContext);
    const { accessToken, profile } = authContext;
    const userCanonicalId = profile?.["https://claims.cimpress.io/canonical_id"];
    const userAccountId = profile?.["https://claims.cimpress.io/account"];
    const userDetails: UserDetails = {
        canonicalId: userCanonicalId,
        accessToken: accessToken,
        accountId: userAccountId
    };

    const { loadingBundle } = useIssueTrackerModule();
    const { dataProduct, dataProductError, loadingDataProduct, loadDataProduct, setDataProduct } = useDataProduct(accessToken, dataProductId);
    const adminCoamGroupHook = Hooks.useCoamGroup(accessToken, dataProduct?.adminCoamGroupId);
    const readCoamGroupHook = Hooks.useCoamGroup(accessToken, dataProduct?.readCoamGroupId);
    const { permissions, loadingPermissions, permissionsError, loadPermissions } = Hooks.useCoamPermissions(accessToken, userCanonicalId);
    const { domain, domainError, loadDomain, loadingDomain } = Hooks.useDomain(accessToken, dataProduct?.domainId);

    const { selectedTab, selectedSubTab } = getSelectedTabs(searchParams);

    const [inlineUpdateError, setInlineUpdateError] = useState(null as Error | null);
    const [loadingInlineUpdate, setLoadingInlineUpdate] = useState({ dataProductName: false, summary: false });

    // Loading Resources Pdw Datasets
    const fetchResourcesPdwDatasets = useMemoizedLoaderForResourcesPdwDatasets(accessToken, dataProductId);

    // Loading Custom Fields
    const fetchCustomFields = useMemoizedLoaderForCustomFields(accessToken, dataProductId);

    // Loading Input Ports
    const fetchRiverStreams = useMemoizedLoaderForRiverStreams(accessToken, dataProductId);
    const cleanErrorsRiverStream = useMemoizedCleanErrorsForRiverStreams(dataProductId);
    const fetchInputPortDatasets = useMemoizedLoaderForInputPortDatasets(accessToken, dataProductId);
    const cleanInputPortDatasets = useMemoizedCleanErrorsForInputPortDatasets(dataProductId);

    // Loading Output Ports
    const pdwDatasetsHook = useOutputPorts(accessToken, dataProductId, DataPortalAPI.OutputPortTypePath.PdwDatasets) as OutputPortHook<DataPortalAPI.OutputPortType.PDWdataset>;
    const fetchPublishedPdwDatasets = useMemoizedLoaderForPublishedPdwDatasets(accessToken, dataProductId);
    const fetchLookerDashboards = useMemoizedLoaderForLookerDashboard(accessToken, dataProductId);
    const fetchUserOwnedPdwDatasets = useMemoizedLoaderForPdwDatasets(accessToken, dataProductId, dataProduct?.domainId);
    const cleanErrorsLooker = useMemoizedCleanErrorsForLookerDashboard(dataProductId);

    // Loading Resources
    const fetchResourcesPdwRoles = usePdwRolesLoader(accessToken, dataProductId);
    const fetchConductors = useConductorDataProductLoader(accessToken, dataProductId);

    const dataProductCoamRolesState = useAppSelector((state) => state.dataProductCoamRoles);

    const { databricksModuleProps, databricksUnits } = useDatabricks(userDetails, dataProduct, dataProductCoamRolesState);
    const { dbtModuleProps, dbtUnits } = useDbt(userDetails, dataProduct, dataProductCoamRolesState);

    useEffect(() => {
        fetchConductors();
    }, [fetchConductors]);

    const [showSuccessSnackBar, setShowSuccessSnackBar] = useState(false);

    useEffect(() => {
        fetchCustomFields();
    }, [fetchCustomFields]);

    useEffect(() => {
        fetchResourcesPdwDatasets();
    }, [fetchResourcesPdwDatasets]);

    useEffect(() => {
        if (userDetails.accessToken == undefined)
            return;
        const baseUrl = process.env.REACT_APP_ENVIRONMENT === ReactAppEnvironment.Production ? 'prd.compute-ui.pdw.cimpress.io' : 'dev.compute-ui.pdw.cimpress.io';    
        appendScript(`https://${baseUrl}/bundle.js`, userDetails.accessToken, "PDWWarehouses");
    }, [userDetails.accessToken]);

    useEffect(() => {
        if (userDetails.accessToken == undefined)
            return;

        const baseUrl = process.env.REACT_APP_ENVIRONMENT === ReactAppEnvironment.Production ? 'prd.ui.databricks-management.cimpress.io' : 'dev.ui.databricks-management.cimpress.io';
        appendScript(`https://${baseUrl}/bundle.js`, userDetails.accessToken, "databricks");
    }, [userDetails.accessToken]);

    useEffect(() => {
        if (userDetails.accessToken == undefined)
            return;

        const baseUrl = process.env.REACT_APP_ENVIRONMENT === ReactAppEnvironment.Production ? 'prd.datasets-ui.databricks-management.cimpress.io' : 'dev.datasets-ui.databricks-management.cimpress.io';
        appendScript(`https://${baseUrl}/bundle.js`, userDetails.accessToken, "databricks-datasets");
    }, [userDetails.accessToken]);

    useEffect(() => {
        if (userDetails.accessToken == undefined)
            return;

        const baseUrl = process.env.REACT_APP_ENVIRONMENT === ReactAppEnvironment.Production ? 'prd.ui.dbt.data.cimpress.io' : 'stg.ui.dbt.data.cimpress.io';
        appendScript(`https://${baseUrl}/bundle.js`, userDetails.accessToken, "dbt");
    }, [userDetails.accessToken]);

    useEffect(() => {
        fetchRiverStreams();
    }, [fetchRiverStreams]);

    useEffect(() => {
        fetchInputPortDatasets();
    }, [fetchInputPortDatasets]);

    useEffect(() => {
        cleanErrorsRiverStream();
    }, [cleanErrorsRiverStream]);

    useEffect(() => {
        cleanInputPortDatasets();
    }, [cleanInputPortDatasets]);

    useEffect(() => {
        fetchLookerDashboards();
    }, [fetchLookerDashboards]);

    useEffect(() => {
        fetchUserOwnedPdwDatasets();
    }, [fetchUserOwnedPdwDatasets]);

    useEffect(() => {
        fetchPublishedPdwDatasets();
    }, [fetchPublishedPdwDatasets]);

    useEffect(() => {
        cleanErrorsLooker();
    }, [cleanErrorsLooker]);


    useEffect(() => {
        searchParams.set('tab', selectedTab);
        if (selectedSubTab) {
            searchParams.set('subtab', selectedSubTab);
        }
        if (history.location && `${history.location.pathname}${searchParams.toString()}` !== `/dataproducts/${dataProductId}${searchParams.toString()}`) // Avoid pushing repeated history
            history.push({ pathname, search: `?${searchParams.toString()}` });
    }, [history, searchParams, pathname, dataProductId, selectedTab, selectedSubTab]);


    useEffect(() => {
        fetchResourcesPdwRoles();
    }, [fetchResourcesPdwRoles]);

    if (loadingDataProduct || loadingPermissions || loadingDomain) {
        return renderLoading('Loading data product...', true);
    }

    if (dataProductError || !dataProduct) {
        return renderError('Error loading data product.', dataProductError || new Error('Data Product not found'), loadDataProduct);
    }

    if (domainError || !domain) {
        return renderError('Error loading data product domain.', domainError || new Error('Data Product Domain not found'), loadDomain);
    }

    if (permissionsError) {
        return renderError('Error loading data product permissions.', permissionsError, loadPermissions);
    }

    const hasDataProductEditAccess = dataProductId && permissions ? Clients.Coam.hasPermission(permissions, Clients.Coam.ResourceType.DataPortalProduct, dataProductId, Clients.Coam.Permission.DataPortalProductEdit) : false;
    const hasDomainGovernPermission = permissions ? Clients.Coam.hasPermission(permissions, Clients.Coam.ResourceType.DataPortalDomain, dataProduct.domainId, Clients.Coam.Permission.DataPortalDomainGovern) : false;
    const hasDataProductManagePermission = hasDataProductEditAccess || hasDomainGovernPermission;

    const isUserReadCoamGroupMember = readCoamGroupHook.coamGroup && userCanonicalId ? Clients.Coam.isCoamGroupMember(readCoamGroupHook.coamGroup, userCanonicalId) : false;
    const isUserAdminCoamGroupMember = adminCoamGroupHook.coamGroup && userCanonicalId ? Clients.Coam.isCoamGroupMember(adminCoamGroupHook.coamGroup, userCanonicalId) : false;
    const hasDataProductAdminAccess = hasDataProductManagePermission || isUserAdminCoamGroupMember;
    const hasDataProductAccess = hasDataProductManagePermission || isUserReadCoamGroupMember || isUserAdminCoamGroupMember;

    if (dbtModuleProps) {
        dbtModuleProps.hasDataProductAccess = hasDataProductAdminAccess;
    }

    const renderDetailsSection = (selectedTab, selectedSubTab) => {
        if (selectedTab === Tabs.InputPorts) {
            return <InputPortsDetailsSection
                selectedSubTab={selectedSubTab}
                dataProduct={dataProduct}
                hasDataProductManagePermission={hasDataProductManagePermission}
            />;
        } else if (selectedTab === Tabs.Resources) {
            return <DataResourceDetailsSection
                hasDataProductAccess={hasDataProductAccess}
                hasDataProductAdminAccess={hasDataProductAdminAccess}
                selectedTab={selectedSubTab as DataPortalAPI.DataResourceType}
                dataProduct={dataProduct}
                dataProductAccountId={domain.accountId}
                userDetails={userDetails}
                pdwDatasetsHook={pdwDatasetsHook}
                useAppSelector={useAppSelector}
                useAppDispatch={useAppDispatch}
                databricksModuleProps={databricksModuleProps}
                dbtModuleProps={dbtModuleProps}
                domain={domain}
            />;
        } else if (selectedTab === Tabs.OutputPorts) {
            return <OutputPortsDetailsSection hasDataProductAdminAccess={hasDataProductAdminAccess} hasDataProductReadAccess={hasDataProductAccess} dataProduct={dataProduct} adminCoamGroupHook={adminCoamGroupHook} readCoamGroupHook={readCoamGroupHook} domain={domain} selectedSubTab={selectedSubTab as DataPortalAPI.OutputPortType} />;
        } else if (selectedTab === Tabs.DataProductAccess) {
            return <AccessManagement dataProduct={dataProduct} domain={domain} hasDataProductManagePermission={hasDataProductManagePermission} />;
        } else if (selectedTab === Tabs.DataIssueTracker) {
            return !loadingBundle && <DataIssueTrackerDetailsSectionModule dataProductId={dataProduct?.dataProductId} history={history} />;
        } else if (selectedTab === Tabs.CostReport) {
            return <DataProductCostReport dataProductId={dataProduct.dataProductId} />;
        }
        return <DataProductDetailsSection
            dataProduct={dataProduct}
            domain={domain}
            hasDataProductPermission={hasDataProductManagePermission}
            updateAttributeFunction={patchSingleDataProductAttribute}
            inlineUpdateError={inlineUpdateError}
            loadingInlineUpdate={loadingInlineUpdate}
            databricksUnits={databricksUnits}
            dbtUnits={dbtUnits}
            setDataProduct={setDataProduct}
        />;
    };

    const patchSingleDataProductAttribute = async (accessToken: string, dataProductId: string, attributeName: string, value: boolean | string) => {
        let result = false;
        setLoadingInlineUpdate({ ...loadingInlineUpdate, ...{ [attributeName]: true } });
        setInlineUpdateError(null);
        try {
            await Clients.DataPortalAPI.patchDataProductAttribute(accessToken, dataProductId, attributeName, value);
            setDataProduct({
                ...dataProduct,
                ...{ [attributeName]: value }
            });
            result = true;
        } catch (error) {
            setInlineUpdateError(error as Error);
        }
        setLoadingInlineUpdate({ ...loadingInlineUpdate, ...{ [attributeName]: false } });
        return result;
    };

    const axiosErrorWrapper = inlineUpdateError ? parseErrorToAxiosErrorWrapper(inlineUpdateError) : null;

    return <div style={{ marginLeft: 100, marginRight: 100 }}>
        <Snackbar show={inlineUpdateError ? true : false} status="danger" delay={10000} onHideSnackbar={() => { setInlineUpdateError(null); }}>
            <p>{axiosErrorWrapper?.defaultTitle}</p>
            {axiosErrorWrapper?.details && (<CodeBlock code={axiosErrorWrapper?.details} />)}
        </Snackbar>
        <div>
            <Breadcrumbs className='breadcrumbs'>
                <BreadcrumbItem><Link to={`/`}>
                    Data Portal
                </Link></BreadcrumbItem>
                <BreadcrumbItem active>
                    {dataProduct.dataProductName}
                </BreadcrumbItem>
            </Breadcrumbs>

            <div>
                <div className='row' style={{ marginBottom: 25, marginTop: 20 }}>
                    <div className='col-md-6' style={{ paddingTop: '1.5rem' }}>
                        {hasDataProductManagePermission
                            ? <div style={{ display: "block", minHeight: 70 }}>
                                {loadingInlineUpdate.dataProductName
                                    ? <><shapes.Spinner size="medium" /><span style={{ color: colors.shale }}>Updating Data Product Name</span></>
                                    : <InlineEdit
                                        name="dataProductName"
                                        size="h2"
                                        label="Data Product"
                                        value={dataProduct.dataProductName}
                                        disabled={!!inlineUpdateError}
                                        onSave={(e) => { if (e.value && accessToken) patchSingleDataProductAttribute(String(accessToken), String(dataProduct.dataProductId), "dataProductName", String(e.value)); }}
                                    />
                                }
                            </div>
                            : <>
                                <h2 data-testid="dpt-name" style={{ marginBottom: 5, marginTop: 5 }}>
                                    {dataProduct.dataProductName}
                                </h2>
                                <small style={{ color: colors.shale }}>Data Product</small>
                            </>
                        }
                    </div>
                    <div className='col-md-6' style={{ paddingTop: '1.5rem' }}>
                        {
                            accessToken && hasDataProductManagePermission ?
                                <>
                                    <div className='pull-right' style={{ marginLeft: '3rem', paddingTop: '0.75rem' }}>
                                        <DeleteDataProduct
                                            dataProduct={dataProduct}
                                        />
                                    </div>
                                    <div className='pull-right'>
                                        <PublishUnpublishDataProductButton
                                            accessToken={accessToken}
                                            dataProductId={dataProductId}
                                            isPublishing={!dataProduct.published}
                                            onSuccess={() => {
                                                setShowSuccessSnackBar(true);
                                                loadDataProduct();
                                            }} />
                                    </div>
                                </>
                                : null
                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-2'>
                        <DataProductDetailsNav
                            selectedTab={selectedTab}
                            selectedSubTab={selectedSubTab}
                            onSelectedTab={(tab, subTab) => {
                                history.push(`/dataproducts/${dataProductId}?tab=${tab}` + (subTab ? '&subtab=' + subTab : ''));
                            }}
                            hasDataProductManagePermission={hasDataProductManagePermission}
                            hasDataProductAccess={hasDataProductAccess}
                            dataProduct={dataProduct}
                            databricksUnitsCount={databricksUnits.length}
                            dbtUnitsCount={dbtUnits.length}
                        />
                    </div>
                    <div className='col-md-10'>
                        {renderDetailsSection(selectedTab, selectedSubTab)}
                    </div>
                </div>
            </div>
        </div >
        <Snackbar show={showSuccessSnackBar} status='success' delay={5000} onHideSnackbar={() => setShowSuccessSnackBar(false)} >
            Your Data product is {dataProduct.published ? 'published' : 'unpublished'} successfully.
        </Snackbar>
    </div >;
};
